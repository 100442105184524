import { HttpResponse, HttpContextToken, HttpContext, HttpHeaders } from '@angular/common/http';
import * as i0 from '@angular/core';
import { InjectionToken, inject, Injectable, PLATFORM_ID, makeEnvironmentProviders } from '@angular/core';
import { EMPTY, of } from 'rxjs';
import { tap, share } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
class KeySerializer {}
class DefaultKeySerializer extends KeySerializer {
  serialize(request, context) {
    const {
      key
    } = context;
    if (key) {
      return (typeof key === 'function' ? key(request) : key).toString();
    }
    return request.urlWithParams;
  }
}
const defaultConfig = {
  strategy: 'explicit',
  mode: 'cache',
  ttl: 3600000 // One hour
};
const HTTP_CACHE_CONFIG = new InjectionToken('HTTP_CACHE_CONFIG');
function injectCacheConfig() {
  return inject(HTTP_CACHE_CONFIG);
}
class HttpCacheStorage extends Map {}
class DefaultHttpCacheStorage extends HttpCacheStorage {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵDefaultHttpCacheStorage_BaseFactory;
      return function DefaultHttpCacheStorage_Factory(__ngFactoryType__) {
        return (ɵDefaultHttpCacheStorage_BaseFactory || (ɵDefaultHttpCacheStorage_BaseFactory = i0.ɵɵgetInheritedFactory(DefaultHttpCacheStorage)))(__ngFactoryType__ || DefaultHttpCacheStorage);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DefaultHttpCacheStorage,
      factory: DefaultHttpCacheStorage.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultHttpCacheStorage, [{
    type: Injectable
  }], null, null);
})();
class TTLManager extends Map {}
class DefaultTTLManager extends TTLManager {
  constructor() {
    super(...arguments);
    this.config = injectCacheConfig();
  }
  isValid(key) {
    return this.get(key) > new Date().getTime();
  }
  set(key, ttl) {
    return super.set(key, new Date().setMilliseconds(ttl || this.config.ttl));
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵDefaultTTLManager_BaseFactory;
      return function DefaultTTLManager_Factory(__ngFactoryType__) {
        return (ɵDefaultTTLManager_BaseFactory || (ɵDefaultTTLManager_BaseFactory = i0.ɵɵgetInheritedFactory(DefaultTTLManager)))(__ngFactoryType__ || DefaultTTLManager);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DefaultTTLManager,
      factory: DefaultTTLManager.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultTTLManager, [{
    type: Injectable
  }], null, null);
})();
class HttpCacheGuard {}
class DefaultHttpCacheGuard {
  canActivate(request) {
    return request.method === 'GET' && request.responseType === 'json';
  }
}
class RequestsQueue extends Map {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵRequestsQueue_BaseFactory;
      return function RequestsQueue_Factory(__ngFactoryType__) {
        return (ɵRequestsQueue_BaseFactory || (ɵRequestsQueue_BaseFactory = i0.ɵɵgetInheritedFactory(RequestsQueue)))(__ngFactoryType__ || RequestsQueue);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: RequestsQueue,
      factory: RequestsQueue.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RequestsQueue, [{
    type: Injectable
  }], null, null);
})();
class CacheBucket extends Set {}
class RequestsCache extends Map {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵRequestsCache_BaseFactory;
      return function RequestsCache_Factory(__ngFactoryType__) {
        return (ɵRequestsCache_BaseFactory || (ɵRequestsCache_BaseFactory = i0.ɵɵgetInheritedFactory(RequestsCache)))(__ngFactoryType__ || RequestsCache);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: RequestsCache,
      factory: RequestsCache.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RequestsCache, [{
    type: Injectable
  }], null, null);
})();
class HttpCacheVersions extends Map {}
class DefaultHttpVersions extends HttpCacheVersions {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵDefaultHttpVersions_BaseFactory;
      return function DefaultHttpVersions_Factory(__ngFactoryType__) {
        return (ɵDefaultHttpVersions_BaseFactory || (ɵDefaultHttpVersions_BaseFactory = i0.ɵɵgetInheritedFactory(DefaultHttpVersions)))(__ngFactoryType__ || DefaultHttpVersions);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DefaultHttpVersions,
      factory: DefaultHttpVersions.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultHttpVersions, [{
    type: Injectable
  }], null, null);
})();
class HttpCacheManager {
  constructor() {
    this.config = injectCacheConfig();
    this.queue = inject(RequestsQueue);
    this.storage = inject(HttpCacheStorage);
    this.guard = inject(HttpCacheGuard);
    this.ttlManager = inject(TTLManager);
    this.requests = inject(RequestsCache);
    this.version = inject(HttpCacheVersions);
  }
  validate(key) {
    const has = this.storage.has(key);
    const isValid = this.ttlManager.isValid(key);
    if (has && isValid) return true;
    this.storage.delete(key);
    return false;
  }
  get(key) {
    return this._resolveResponse(this.storage.get(key));
  }
  has(key) {
    return this.storage.has(key);
  }
  set(key, body, {
    ttl,
    bucket
  } = {}) {
    let response = body;
    if (!(body instanceof HttpResponse)) {
      response = new HttpResponse({
        body,
        status: 200,
        url: key
      });
    }
    this._set(key, response, ttl);
    bucket && bucket.add(key);
  }
  delete(key, {
    deleteRequests,
    deleteVersions
  } = {}) {
    if (key instanceof CacheBucket) {
      key.forEach(value => this.delete(value));
      key.clear();
      return;
    }
    this.storage.delete(key);
    this.ttlManager.delete(key);
    this.queue.delete(key);
    if (deleteRequests !== false) {
      this._getRequests().delete(key);
    }
    if (deleteVersions !== false) {
      this._getVersions().delete(key);
    }
  }
  clear() {
    this.storage.clear();
    this.ttlManager.clear();
    this.queue.clear();
    this._getVersions().clear();
    this._getRequests().clear();
  }
  _getQueue() {
    return this.queue;
  }
  _getRequests() {
    return this.requests;
  }
  _getVersions() {
    return this.version;
  }
  _isCacheable(canActivate, cache) {
    const strategy = this.config.strategy;
    if (strategy === 'explicit') {
      return cache;
    }
    if (canActivate && strategy === 'implicit') {
      return cache !== false;
    }
    return false;
  }
  _set(key, response, ttl) {
    this.storage.set(key, response);
    this.ttlManager.set(key, ttl);
  }
  _canActivate(request) {
    return this.guard.canActivate(request);
  }
  _resolveResponse(event) {
    return this.config.responseSerializer ? event.clone({
      body: this.config.responseSerializer(event.body)
    }) : event;
  }
  static {
    this.ɵfac = function HttpCacheManager_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || HttpCacheManager)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HttpCacheManager,
      factory: HttpCacheManager.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HttpCacheManager, [{
    type: Injectable
  }], null, null);
})();
const CACHE_CONTEXT = new HttpContextToken(() => ({}));
function withCache(options = {}) {
  const {
    context,
    ...remainingOptions
  } = options;
  return (context ?? new HttpContext()).set(CACHE_CONTEXT, {
    cache: true,
    returnSource: EMPTY,
    ...remainingOptions
  });
}
const httpCacheInterceptor = (request, next) => {
  const config = injectCacheConfig();
  const httpCacheManager = inject(HttpCacheManager);
  const keySerializer = inject(KeySerializer);
  const platformId = inject(PLATFORM_ID);
  const context = request.context.get(CACHE_CONTEXT);
  if (isPlatformServer(platformId)) {
    return next(request);
  }
  const key = keySerializer.serialize(request, context);
  const {
    cache = config.strategy === 'implicit',
    ttl,
    bucket,
    clearCachePredicate,
    version,
    mode,
    returnSource
  } = context;
  if (version) {
    const versions = httpCacheManager._getVersions();
    const currentVersion = versions.get(key);
    if (currentVersion !== version) {
      httpCacheManager.delete(key);
    }
    versions.set(key, version);
  }
  if (key && clearCachePredicate) {
    const requests = httpCacheManager._getRequests();
    const clearCache = clearCachePredicate(requests.get(key), requests.set(key, request).get(key), key);
    if (clearCache) {
      httpCacheManager.delete(key, {
        deleteRequests: false,
        deleteVersions: false
      });
    }
  }
  const canActivate = httpCacheManager._canActivate(request);
  if (httpCacheManager._isCacheable(canActivate, cache)) {
    const queue = httpCacheManager._getQueue();
    bucket && bucket.add(key);
    if (queue.has(key)) {
      return queue.get(key);
    }
    if (httpCacheManager.validate(key)) {
      return mode === 'stateManagement' ? returnSource : of(httpCacheManager.get(key));
    }
    const shared = next(request).pipe(tap(event => {
      if (event instanceof HttpResponse) {
        if (mode === 'stateManagement') {
          httpCacheManager._set(key, true, ttl || config.ttl);
        } else {
          const cache = httpCacheManager._resolveResponse(event);
          httpCacheManager._set(key, cache, ttl || config.ttl);
        }
        queue.delete(key);
      }
    }, err => queue.delete(key)), share());
    queue.set(key, shared);
    return shared;
  }
  return next(request);
};
function withHttpCacheInterceptor() {
  return httpCacheInterceptor;
}
function provideHttpCache(config = {}) {
  return makeEnvironmentProviders([{
    provide: HTTP_CACHE_CONFIG,
    useValue: {
      ...defaultConfig,
      ...config
    }
  }, {
    provide: KeySerializer,
    useClass: DefaultKeySerializer
  }, {
    provide: HttpCacheStorage,
    useClass: DefaultHttpCacheStorage
  }, {
    provide: TTLManager,
    useClass: DefaultTTLManager
  }, {
    provide: HttpCacheGuard,
    useClass: DefaultHttpCacheGuard
  }, {
    provide: HttpCacheVersions,
    useClass: DefaultHttpVersions
  }, HttpCacheManager, RequestsQueue, RequestsCache]);
}
const storage = {
  clearItem(key) {
    localStorage.removeItem(key);
  },
  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  getItem(key) {
    const value = localStorage.getItem(key);
    if (!value) {
      return undefined;
    }
    return JSON.parse(value);
  }
};
const KEY$2 = `@cache`;
function createKey$2(key) {
  return `${KEY$2}-${key}`;
}
class HttpCacheLocalStorage extends HttpCacheStorage {
  has(key) {
    const generatedKey = createKey$2(key);
    return super.has(generatedKey) || !!storage.getItem(generatedKey);
  }
  get(key) {
    const generatedKey = createKey$2(key);
    const cacheValue = super.get(generatedKey);
    if (cacheValue) {
      return cacheValue;
    }
    const value = storage.getItem(generatedKey);
    if (value) {
      value.headers = new HttpHeaders(value.headers);
      value.headers.init();
      const response = new HttpResponse(value);
      super.set(generatedKey, response);
    }
    return super.get(generatedKey);
  }
  set(key, response) {
    const generatedKey = createKey$2(key);
    const httpResponse = {
      ...response,
      headers: {}
    };
    response.headers.keys().forEach(headerKey => {
      httpResponse.headers[headerKey] = response.headers.get(headerKey);
    });
    storage.setItem(generatedKey, httpResponse);
    return super.set(generatedKey, response);
  }
  delete(key) {
    const generatedKey = createKey$2(key);
    storage.clearItem(generatedKey);
    return super.delete(generatedKey);
  }
  clear() {
    super.forEach((value, key) => {
      super.delete(key);
      storage.clearItem(key);
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵHttpCacheLocalStorage_BaseFactory;
      return function HttpCacheLocalStorage_Factory(__ngFactoryType__) {
        return (ɵHttpCacheLocalStorage_BaseFactory || (ɵHttpCacheLocalStorage_BaseFactory = i0.ɵɵgetInheritedFactory(HttpCacheLocalStorage)))(__ngFactoryType__ || HttpCacheLocalStorage);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HttpCacheLocalStorage,
      factory: HttpCacheLocalStorage.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HttpCacheLocalStorage, [{
    type: Injectable
  }], null, null);
})();
const KEY$1 = `@ttl`;
function createKey$1(key) {
  return `${KEY$1}-${key}`;
}
class LocalStorageTTLManager extends TTLManager {
  constructor() {
    super(...arguments);
    this.ttl = new DefaultTTLManager();
    this.config = injectCacheConfig();
  }
  isValid(key) {
    const valid = this.ttl.isValid(createKey$1(key));
    if (valid) {
      return true;
    }
    const localStorageTimeStamp = storage.getItem(createKey$1(key));
    const validInStorage = localStorageTimeStamp > new Date().getTime();
    if (validInStorage) {
      this.ttl.set(createKey$1(key), localStorageTimeStamp - new Date().getTime());
    }
    return validInStorage;
  }
  set(key, ttl) {
    const resolveTTL = ttl ?? this.config.ttl;
    storage.setItem(createKey$1(key), new Date().setMilliseconds(resolveTTL));
    this.ttl.set(createKey$1(key), resolveTTL);
    return this;
  }
  delete(key) {
    this.ttl.delete(createKey$1(key));
    storage.clearItem(createKey$1(key));
    return true;
  }
  clear() {
    this.ttl.forEach((_, key) => {
      this.ttl.delete(key);
      storage.clearItem(key);
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵLocalStorageTTLManager_BaseFactory;
      return function LocalStorageTTLManager_Factory(__ngFactoryType__) {
        return (ɵLocalStorageTTLManager_BaseFactory || (ɵLocalStorageTTLManager_BaseFactory = i0.ɵɵgetInheritedFactory(LocalStorageTTLManager)))(__ngFactoryType__ || LocalStorageTTLManager);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LocalStorageTTLManager,
      factory: LocalStorageTTLManager.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalStorageTTLManager, [{
    type: Injectable
  }], null, null);
})();
const KEY = `@version`;
function createKey(key) {
  return `${KEY}-${key}`;
}
class LocalStorageVersionsManager extends HttpCacheVersions {
  has(key) {
    return super.has(createKey(key)) || !!storage.getItem(createKey(key));
  }
  get(key) {
    const cacheValue = super.get(createKey(key));
    if (cacheValue) {
      return cacheValue;
    }
    const value = storage.getItem(createKey(key));
    if (value) {
      super.set(createKey(key), value);
    }
    return super.get(createKey(key));
  }
  set(key, version) {
    storage.setItem(createKey(key), version);
    return super.set(createKey(key), version);
  }
  delete(key) {
    super.delete(key);
    storage.clearItem(key);
    return true;
  }
  clear() {
    super.forEach((_, key) => {
      super.delete(key);
      storage.clearItem(key);
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵLocalStorageVersionsManager_BaseFactory;
      return function LocalStorageVersionsManager_Factory(__ngFactoryType__) {
        return (ɵLocalStorageVersionsManager_BaseFactory || (ɵLocalStorageVersionsManager_BaseFactory = i0.ɵɵgetInheritedFactory(LocalStorageVersionsManager)))(__ngFactoryType__ || LocalStorageVersionsManager);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LocalStorageVersionsManager,
      factory: LocalStorageVersionsManager.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalStorageVersionsManager, [{
    type: Injectable
  }], null, null);
})();
function provideHttpCacheLocalStorageStrategy() {
  return makeEnvironmentProviders([{
    provide: HttpCacheStorage,
    useClass: HttpCacheLocalStorage
  }, {
    provide: TTLManager,
    useClass: LocalStorageTTLManager
  }, {
    provide: HttpCacheVersions,
    useClass: LocalStorageVersionsManager
  }]);
}
function requestDataChanged(previousRequest, currentRequest) {
  return previousRequest?.urlWithParams !== currentRequest.urlWithParams || JSON.stringify(previousRequest?.body) !== JSON.stringify(currentRequest.body);
}

/**
 * Generated bundle index. Do not edit.
 */

export { CacheBucket, DefaultHttpCacheGuard, DefaultKeySerializer, DefaultTTLManager, HttpCacheGuard, HttpCacheManager, HttpCacheStorage, KeySerializer, TTLManager, provideHttpCache, provideHttpCacheLocalStorageStrategy, requestDataChanged, withCache, withHttpCacheInterceptor };
